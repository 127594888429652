.circle {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  font-size: 20px;
  line-height: 20px;
  border-radius: 50%;
  font-weight: 400;
  border: 2px solid white;
}

.list {
  flex: 1;
  height: 100%;
  width: 20rem;
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
  align-items: stretch;

  .list-header {
    height: 3rem;
    display: flex;
    font-weight: 700;
    padding: 0 0.5rem;
    align-items: center;
    justify-content: space-between;

    .add {
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .list-content {
    display: flex;
    justify-content: center;
    padding: 1rem;
    overflow: auto;
    height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);

    .content-droppable {
      width: 10rem;
    }
  }

  &.blue {
    .list-header {
      height: 3rem;
      background-color: #1991D7;
    }

    .list-content {
      background-color: #BEE1F5;
    }
  }

  &.red {
    .list-header {
      height: 3rem;
      background-color: #E12855;
    }

    .list-content {
      background-color: #F5BEC3;
    }
  }

  &.gray {
    .list-header {
      height: 3rem;
      background-color: #0F233C;
    }

    .list-content {
      background-color: #B9C3C8;
    }
  }
}

.toast {
  cursor: pointer;
  left: 3rem;
  bottom: 2rem;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.25rem;
  height: 5rem;
  color: white;
  font-size: 24px;
  padding: 0 3rem;
  background-color: #E12855;
  opacity: 1;
  transition: opacity 0.5s ease;

  .dismiss-message {
    font-size: 16px;
    opacity: 0.75;
    transition: opacity 0.5s ease;
  }

  &.hidden {
    opacity: 0;

    .dismiss-message {
      opacity: 0;
    }
  }
}

.ticket {
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  display: flex;
  font-size: 18px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;

  .ticket-content {
    text-align: center;
    word-break: break-word;
  }

  .ticket-placeholder {
    opacity: 0.5;
    text-align: center;
  }

  textarea {
    height: 100%;
    width: 100%;
    resize: none;
    word-break: break-word;
    font-size: 18px;
    color: white;
    border: none;
    outline: none;
    text-align: center;
    background-color: transparent;
    font-family: "Roboto", serif;
  }

  .delete {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
    visibility: hidden;
    color: white;
    font-size: 18px;
    font-weight: 700;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .delete {
      visibility: visible;
    }
  }

  &.blue {
    background-color: #55AFE1;
  }

  &.red {
    background-color: #E66978;
  }

  &.gray {
    background-color: #4B5B73;
  }

  &.selected {
    background-color: #FFA500;
  }

  &.obscured {
    opacity: 0.5;
  }
}

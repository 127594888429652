.app {
  color: white;
  height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #282c34;

  main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

header {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-content {
    width: 61rem;

    .layout {
      width: 40.5rem;
      column-gap: 0.5rem;
      margin-left: 20.5rem;
      display: flex;
      align-items: center;

      h2 {
        width: 20rem;
        text-align: center;
      }
    }
  }
}

.search {
  flex: 1;
  color: white;
  border: none;
  margin: 0;
  font-size: 16px;
  padding: 0.5rem 1rem;
  height: 2rem;
  width: 20rem;
  box-sizing: border-box;
  background-color: lighten($color: #282c34, $amount: 10%);
}
